import {Injectable} from '@angular/core'; 
import { CartDTO, CustomerDTO, EmployeeDTO, ProductDTO, PromoDTO, ServiceDTO } from 'app/domain/model';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SessionService {


   // variabile globale, utilizzaa per condividere i dati scaricati da tutti i componenti

   private services = new BehaviorSubject<ServiceDTO[]>([]);

   getServices = this.services.asObservable();
   setServices(s : ServiceDTO[])
   {
      this.services.next(s);
   }


   private products = new BehaviorSubject<ProductDTO[]>([]);

   getProducts = this.products.asObservable();
   setProducts(s : ProductDTO[])
   {
      this.products.next(s);
   }



   private employees = new BehaviorSubject<EmployeeDTO[]>([]);

   getEmployees = this.employees.asObservable();
   setEmployees(s : EmployeeDTO[])
   {
      this.employees.next(s);
   }


   private customers = new BehaviorSubject<CustomerDTO[]>([]);

   getCustomers = this.customers.asObservable();
   setCustomers(s : CustomerDTO[])
   {
      this.customers.next(s);
   }


   private carts = new BehaviorSubject<CartDTO[]>([]);

   getSessionCarts = this.carts.asObservable();
   setSessionCarts(s : CartDTO[])
   {
      this.carts.next(s);
   }


   
   private promos = new BehaviorSubject<PromoDTO>({});

   getPromos = this.promos.asObservable();
   setPromos(s : PromoDTO)
   {
      this.promos.next(s);
   }

}