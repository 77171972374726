import {Injectable} from '@angular/core'; 
import { SettingDTO } from 'app/domain/model';

@Injectable({providedIn: 'root'})
export class AppStorageManager {


  set(key: string, data: any): void 
  {
    localStorage.setItem(key, JSON.stringify(data)); 
    //sessionStorage.setItem(key, JSON.stringify(data)); 
  }
 
  get(key: string): any 
  {
    let data = localStorage.getItem(key);
    //let data = sessionStorage.getItem(key);
    if(data)
    {
      return JSON.parse(data) as SettingDTO;
    }
    return null;
  }

 
  remove(key: string): void {
    localStorage.removeItem(key);
    //sessionStorage.removeItem(key);
  }

  clear(key: string): void {
    localStorage.clear();
    //sessionStorage.clear();
  }

}