<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [autoCollapse]="true" [appearance]="'compact'"
    [mode]="isScreenSmall ? 'over' : 'over'" [name]="'mainNavigation'" [navigation]="navigation?.compact"
    [opened]="false">
    <!-- [opened]="!isScreenSmall"> 
    [mode]="isScreenSmall ? 'over' : 'side'"
-->
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <!-- <div class="flex items-center justify-center h-20 mt-3 mb-4">
            <img
                class="w-10"
                src="assets/images/logo/logo.svg"
                alt="Logo image">
        </div> -->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 ">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <div class="items-start">
            <img src="../../../../../assets/images/logo/logo-on-white.svg" class="h-16 w-auto">
        </div>

        <h3 class="hidden lg:inline-flex">
            <span  *ngIf="env != 'prd'" class="mr-2 ml-2 bg-red-600 text-white px-2 font-extrabold uppercase">{{env}}</span>
        </h3>
        
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">

            <a [routerLink]="['/cash']" *ngIf="!hideCashFunctions"
                class="items-center py-2 rounded-md bg-gray-100 text-secondary hover:bg-gray-200  hover:text-secondary h-full hidden lg:inline"
                mat-stroked-button>
                <span class="sm:inline-flex  px-6">
                    <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_outline:banknotes'"></mat-icon>
                    <span class="ml-2 mt-1 uppercase"><ng-container *transloco="let t">{{t('cash')}}</ng-container>
                    </span>
                </span>
            </a>
            <a [routerLink]="['/scheduler']" *ngIf="!hideCashFunctions"
                class="items-center py-2 rounded-md bg-gray-100 text-secondary hover:bg-gray-200  hover:text-secondary h-full hidden lg:inline"
                mat-stroked-button>
                <span class="sm:inline-flex  px-6">
                    <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_outline:calendar'"></mat-icon>
                    <span class="ml-2 mt-1 uppercase"><ng-container
                            *transloco="let t">{{t('scheduler')}}</ng-container></span>
                </span>
            </a>
            <div class="hidden lg:inline-flex">
                <div class="px-3 py-2 rounded-md border border-solid"
                    [ngClass]="[expireDays() > 30 ? 'bg-green-50 border-green-400' : '',  expireDays() <= 30  && expireDays() > 5 ? 'bg-orange-50 border-orange-400' : '',expireDays() <= 5 ? 'bg-red-50 border-red-400' : '']">
                    <!-- <strong>{{user?.name}}</strong>--> Cod. <b><strong>{{user?.code}}</strong></b> - <ng-container *transloco="let t">{{t('subscriptionExpire')}}</ng-container> 
                    <strong>{{user?.expireDate | date:'dd/MM/YY' }}</strong>
                </div>
                <div class="px-3 py-2 rounded-md border border-solid ml-2"
                    [ngClass]="[credit > 30 ? 'bg-green-50 border-green-400' : '',  credit <= 30  && credit > 5 ? 'bg-orange-50 border-orange-400' : '', credit <= 5 ? 'bg-red-50 border-red-400' : '']">
                    <!-- [ngClass]="[user.creditSms > 30 ? 'bg-green-50 border-green-400' : '',  user.creditSms <= 30  && user.creditSms > 5 ? 'bg-orange-50 border-orange-400' : '',user.creditSms <= 5 ? 'bg-red-50 border-red-400' : '']"> -->

                    <ng-container *transloco="let t">{{t('smsCredit')}}</ng-container> : 
                    <strong>{{credit}}</strong>
                </div>
            </div>
            
            <!-- Button -->
            <button mat-icon-button [matMenuTriggerFor]="userActions" *ngIf="!hideDocumentFuctions">
                <span class="relative">
                    <mat-icon [svgIcon]="'heroicons_outline:printer'"></mat-icon>
                </span>
            </button>

            <mat-menu [xPosition]="'before'" #userActions="matMenu">
                <button mat-menu-item (click)="documents()">

                    <span class="first-letter:uppercase"><ng-container
                            *transloco="let t">{{t('documents')}}</ng-container></span>
                </button>
                <button mat-menu-item (click)="documentsDeleted()">

                    <span class="first-letter:uppercase"><ng-container
                            *transloco="let t">{{t('documentsDeleted')}}</ng-container></span>
                </button>

                <button mat-menu-item (click)="reportDaily()">

                    <span class="first-letter:uppercase"><ng-container
                            *transloco="let t">{{t('reportDaily')}}</ng-container></span>
                </button>

              
            </mat-menu>

           

            <button mat-icon-button class="hidden lg:inline" (click)="openBirthday()">
                <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
                    <span *ngIf="birthdayCount > 0"
                        class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                        {{birthdayCount}}
                    </span>
                </span>
                <mat-icon [svgIcon]="'heroicons_outline:cake'"></mat-icon>
            </button>

            <a class="hidden lg:inline hover:bg-gray-100 px-2 py-1 rounded-full" title="Supporto" target="_blank" 
            href="https://wa.me/+393519355253?text=Buongiorno%20avrei%20bisogno%20di%20supporto"
            >                
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </a>
            
            <languages></languages>

            
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <!-- <notifications></notifications> -->
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button>  -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">TopSalon &copy; {{currentYear}}</span>
    </div> -->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat>
 -->