import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';  
import { Observable } from 'rxjs';
import { ApiResponse, SettingDTO } from 'app/domain/model';
import { Api, Media } from 'app/api';
import { MobytCreditResponse } from 'app/domain/media.model';


@Injectable({providedIn: 'root'})
export class SettingService  { 

  constructor(private http: HttpClient) { }

  public get() : Observable<SettingDTO> {
    return this.http.get<SettingDTO>(Api.Setting);
  }

  public update(dto : SettingDTO) : Observable<ApiResponse> {
      return this.http.put<ApiResponse>(Api.Setting, dto);
  }

  public updateComunication(dto : SettingDTO) : Observable<ApiResponse> {
    return this.http.put<ApiResponse>(Api.SettingComunication, dto);
}
 
  public getMobytAlias() : Observable<string[]> {
    return this.http.get<string[]>(Media.GetMobytAlias);
  }

  public getMobytCredit() : Observable<MobytCreditResponse> {
    return this.http.get<MobytCreditResponse>(Media.GetMobytCredit);
  }
}
