import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
    const authService = inject(AuthService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    let _url = req.url;
    if(_url.startsWith("auth/"))
        _url = environment.authUrl + _url.replace("auth", "");
    if(_url.startsWith("api/"))
        _url = environment.apiUrl + _url.replace("api", "");
    if(_url.startsWith("media/"))
        _url = environment.mediaUrl + _url.replace("media", "");

    if ( authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken) )
    {
        if(_url.indexOf("fpmate") < 0 && _url.indexOf("service.cgi") < 0)
        {
            newReq = req.clone({ 
                url: _url,
                headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
            });
        }
    } 
    else{
        newReq = req.clone({ 
            url: _url
        });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) =>
        {
            // Catch "401 Unauthorized" responses
            if ( error instanceof HttpErrorResponse && error.status === 401 )
            {
                // Sign out
                authService.signOut();

                // Reload the app
                location.reload();
            }

            return throwError(error);
        }),
    );
};
