import { CommonModule, NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { Component, OnDestroy, OnInit, ViewEncapsulation,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet, RouterLink } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment'
import { CacheService } from 'app/services/CacheService';
import { CustomerDTO, SettingDTO } from 'app/domain/model';
import { SettingService } from 'app/services/SettingService';
import { AppStorageManager } from 'app/manager/AppStorageManager';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { MatDividerModule } from '@angular/material/divider';

import { MatMenuModule } from '@angular/material/menu';
import { SessionService } from 'app/services/SessionService';
import { MatDialog } from '@angular/material/dialog';
import { CustomerBirthdayPopupComponent } from 'app/components/customer-birthday-popup/customer-birthday-popup.component';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'assets/storage/navigation';
import { cloneDeep } from 'lodash-es';
import { UserRole } from 'app/domain/auth.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MobytCreditResponse } from 'app/domain/media.model';


@Component({
    selector     : 'compact-layout',
    templateUrl  : './compact.component.html',
    encapsulation: ViewEncapsulation.None,
    
    standalone   : true,
    imports      : [TranslocoModule, CommonModule, RouterLink, MatDividerModule,MatMenuModule, FuseLoadingBarComponent, MatButtonModule, MatIconModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, NotificationsComponent, UserComponent, NgIf, RouterOutlet, QuickChatComponent, FuseVerticalNavigationComponent],
})
export class CompactLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean; 
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    env : String = "x";
    user: User;
    currentRoute: string;
    customers : Array<CustomerDTO> = [];
    birthdayCount : number = 0;
    navigation: Navigation;

    credit : number = 0;

    hideCashFunctions : boolean = false;
    hideDocumentFuctions : boolean = false;

    private  _compactNavigation: FuseNavigationItem[] = compactNavigation; 
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    public setting : SettingDTO = {};

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _storageManager : AppStorageManager,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _cacheService: CacheService,
        private _matDialog: MatDialog, 
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserService,
        private _sessionService: SessionService,
        private _settingService : SettingService
    )
    {
        
    }
 
    get currentYear(): number
    {
        return new Date().getFullYear();
    }
 
    ngOnInit(): void
    {
        this.env = environment.env;
 
        this.setting = this._storageManager.get("settings");

        setTimeout(()=>{  
            this.loadBirthday(); 
            this.loadCredit();
        }, 2000);
 
        
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) =>
            {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        // Subscribe to user changes
        this._userService.user$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((user: User) =>
          {
              this.user = user;
              // Mark for check 
              if(this.user.role != UserRole.Admin && this.user.role != UserRole.Responsabile)
                this.hideDocumentFuctions = true;

              if(this.user.role == UserRole.Marketing)
                this.hideCashFunctions = true;
              
            this.loadMenu();

              this._changeDetectorRef.markForCheck();
          });
    }
 
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    loadCredit()
    {
        this._settingService.getMobytCredit().subscribe({
            next: (res: MobytCreditResponse) => {
                this.credit = 0;
                if(res && res.sms && res.sms.find(x => x.type == "L"))
                    this.credit = res.sms.find(x => x.type == "L").quantity;
            },
            error: (err: HttpErrorResponse) => {}
        });
    }

    loadMenu()
    {
        this._compactNavigation.forEach((compactNavItem) =>
        {
            this._defaultNavigation.forEach((defaultNavItem) =>
            {
                if ( defaultNavItem.id === compactNavItem.id )
                {
                    compactNavItem.children = cloneDeep(defaultNavItem.children);

                    //compactNavItem.children = compactNavItem.children.filter(x => !x.meta || x.meta.indexOf(this.user.role) >= 0);
                }
            });
        });
        
        this._compactNavigation = this._compactNavigation.filter(x => !x.meta || x.meta.indexOf(this.user.role) >= 0);
        this.navigation =
            {
                compact   : cloneDeep(this._compactNavigation),
                default   : cloneDeep(this._defaultNavigation),
                futuristic: cloneDeep(this._futuristicNavigation),
                horizontal: cloneDeep(this._horizontalNavigation),
            };

        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) =>
        //     {
        //         this.navigation = navigation;
        //     });

    }

    loadBirthday()
    {
        this._sessionService.getCustomers.subscribe(x => { 
            this.customers = [];
            x.forEach(c => {
                if(c.birtday)
                {
                    let d = (new Date()).getDate();                    
                    let m = (new Date()).getMonth();

                    let b : Date = new Date(c.birtday);
                    if(b.getDate() == d && b.getMonth() == m)
                        this.customers.push(c);
                }
            });
            
            this.birthdayCount = this.customers.length;
          });
    }

    openBirthday()
    {
        const dialogRef = this._matDialog.open(CustomerBirthdayPopupComponent);
        dialogRef.componentInstance.customers = this.customers;

    }
 
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    documents(): void
    {
        this._router.navigate(['/documents']);
    }

    documentsDeleted(): void
    {
        this._router.navigate(['/deleted-documents']);
    }

    expireDays() {
        let currentDate = new Date();
        let dateSent = new Date(this.user.expireDate);

        return -1 * Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
    }

    reportDaily(): void
    {
        this._router.navigate(['/daily-report']);
    }
}
