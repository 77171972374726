import { AcademyMockApi } from 'app/mock-api/apps/academy/api';
import { ChatMockApi } from 'app/mock-api/apps/chat/api';
import { ContactsMockApi } from 'app/mock-api/apps/contacts/api';
import { ECommerceInventoryMockApi } from 'app/mock-api/apps/ecommerce/inventory/api';
import { FileManagerMockApi } from 'app/mock-api/apps/file-manager/api';
import { HelpCenterMockApi } from 'app/mock-api/apps/help-center/api';
import { MailboxMockApi } from 'app/mock-api/apps/mailbox/api';
import { NotesMockApi } from 'app/mock-api/apps/notes/api';
import { ScrumboardMockApi } from 'app/mock-api/apps/scrumboard/api';
import { TasksMockApi } from 'app/mock-api/apps/tasks/api';
import { AuthMockApi } from 'app/mock-api/common/auth/api';
import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { UserMockApi } from 'app/mock-api/common/user/api';
import { AnalyticsMockApi } from 'app/mock-api/dashboards/analytics/api';
import { CryptoMockApi } from 'app/mock-api/dashboards/crypto/api';
import { FinanceMockApi } from 'app/mock-api/dashboards/finance/api';
import { ProjectMockApi } from 'app/mock-api/dashboards/project/api';
import { ActivitiesMockApi } from 'app/mock-api/pages/activities/api';
import { IconsMockApi } from 'app/mock-api/ui/icons/api';

export const mockApiServices = [
    //AcademyMockApi,
    //ActivitiesMockApi,
    //AnalyticsMockApi,
    //AuthMockApi,
    //ChatMockApi,
    //ContactsMockApi,
    //CryptoMockApi,
    //ECommerceInventoryMockApi,
    //FileManagerMockApi,
    //FinanceMockApi,
    //HelpCenterMockApi,
    IconsMockApi,
    //MailboxMockApi,
    //MessagesMockApi,
    NavigationMockApi,
    //NotesMockApi,
    //NotificationsMockApi,
    //ProjectMockApi,
    //SearchMockApi,
    //ScrumboardMockApi,
    //ShortcutsMockApi,
    //TasksMockApi,
    //UserMockApi,
];
