import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { enableProdMode } from '@angular/core';



import { registerLicense } from '@syncfusion/ej2-base';
import { environment } from 'app/environments/environment';

// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { AppModule } from './app/app.module';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXZccnRQQ2lYVkd1Vkc=');

if (environment.production) 
{
    enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));

  
// platformBrowserDynamic().bootstrapModule(AppModule)
//     .catch(err => console.error(err));
  
