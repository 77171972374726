// import * as npm from "../../../package.json";


export const environment = {
  production: true,
  env: "uat", 
    // version: npm.version,
  authUrl: 'https://topsalon-auth.codefab.it',
  apiUrl: 'https://topsalon-api.codefab.it',
  mediaUrl: 'https://topsalon-media.codefab.it'
};

