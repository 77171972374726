<div
  class="flex flex-col max-w-full w-[600px] md:rounded-lg border border-gray-50 shadow-xl min-h-full md:min-h-0 bg-gray-50 ">
  <div class="flex flex-row justify-between px-6 py-3  bg-gray-50 md:rounded-tl-lg md:rounded-tr-lg">
    <p class="font-semibold text-indigo-900 first-letter:uppercase text-lg">
      <ng-container *transloco="let t">{{t('birthdayCustomer')}}</ng-container>
    </p>

    <button (click)="closeDialog()">
      <mat-icon class="icon-size-5 text-indigo-900" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
    </button>
  </div>




  <div class="flex flex-col px-3 bg-white">


    <div *ngIf="customers && customers.length > 0; else noBirthday" class="grid grid-cols-1  bg-white h-full mt-4">
      <table class="w-full table-auto">
        <thead>
          <tr class="font-semibold text-md text-secondary-dark border-b border-dashed last:border-b-1 bg-gray-100 ">
            <th class="py-2 px-3 text-left rounded-tl-md first-letter:uppercase"><ng-container
              *transloco="let t">{{t('day')}}</ng-container></th>
            <th class="py-2 px-3 text-left rounded-tl-md first-letter:uppercase"><ng-container
                *transloco="let t">{{t('nominative')}}</ng-container></th>
            <th class="py-2 px-3 text-left  first-letter:uppercase"><ng-container
                *transloco="let t">{{t('years')}}</ng-container></th>
            <th class="rounded-tr-md"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let c of customers" class="border-b border-dashed hover:bg-gray-50">
           
            <td class="py-2 px-3">{{c.firstName}} {{c.lastName}}</td>
            <td class="py-2 px-3">{{getAge(c)}} <ng-container *transloco="let t">{{t('years')}}</ng-container></td>
            <td class="p-2 text-right">

              <a *ngIf="c.mobile != ''" target="_blank"
                href="https://wa.me/+39{{c.mobile}}?text=Tantissimi%20auguri%20di%20buon%20compleanno"
                class="inline-flex  rounded-md  px-3 py-2 text-md font-semibold bg-teal-600 text-on-primary hover:bg-teal-700 first-letter:uppercase">
                <mat-icon class="icon-size-4 text-white mr-2" svgIcon="heroicons_outline:cake"></mat-icon>
                <ng-container *transloco="let t">{{t('sendWappMessage')}}</ng-container>
              </a>
            </td>
          </tr>


        </tbody>
      </table>

    </div>

    <ng-template #noBirthday>
      <span class="bg-gray-100 w-full block px-3 py-2 rounded-md text-lg text-center border border-gray-200">

        <ng-container *transloco="let t">{{t('nobirthdaytoday')}}</ng-container>
      </span>
    </ng-template>

  </div>

  <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">



    <button (click)="closeDialog()" type="button"
      class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
      <span class="sm:inline-flex">
        <mat-icon class="icon-size-4 text-indigo-900" svgIcon="feather:chevrons-left"></mat-icon>
        <span class="ml-2 first-letter:uppercase">
          <ng-container *transloco="let t">{{t('cancel')}}</ng-container>
        </span>
      </span>
    </button>

  </div>


</div>