import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'cash'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'cash'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            // {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', title : 'TopSalon - Login - Booking OnLine per Parrucchieri, Centri Estetici e Barber Shop', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            // {path: 'reset', loadChildren: () => import('app/modules/auth/reset/reset.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'reset', loadChildren: () => import('app/modules/auth/reset/reset.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'auto-sign-in', loadChildren: () => import('app/modules/auth/auto-sign-in/auto-sign-in.routes')},   // {path: 'sign-up', title : 'TopSalon - Registrazione', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            
            {path: 'cash', title : 'TopSalon - Cassa', loadChildren: () => import('app/modules/cash/cash.routes')},
            {path: 'scheduler', title : 'TopSalon - Agenda', loadChildren: () => import('app/modules/scheduler/scheduler.routes')},
            {path: 'workshift', title : 'TopSalon - Turni di lavoro', loadChildren: () => import('app/modules/workshift/workshift.routes')},
            {path: 'absence', title : 'TopSalon - Registrazione', loadChildren: () => import('app/modules/workshift/absence/absence.routes')},
            {path: 'empty', loadChildren: () => import('app/modules/empty/empty.routes')},
            
            //anagrafiche
            {path: 'customers', title : 'TopSalon - Clienti', loadChildren: () => import('app/modules/admin/customers/customers.routes')},
            {path: 'employees', title : 'TopSalon - Collaboratori', loadChildren: () => import('app/modules/admin/employees/employees.routes')},
            {path: 'pricelists', title : 'TopSalon - Listini', loadChildren: () => import('app/modules/admin/pricelists/pricelists.routes')},
            {path: 'services', title : 'TopSalon - Servizi', loadChildren: () => import('app/modules/admin/services/services.routes')},
            {path: 'products', title : 'TopSalon - Prodotti', loadChildren: () => import('app/modules/admin/products/products.routes')},
            {path: 'categories', title : 'TopSalon - Categorie', loadChildren: () => import('app/modules/admin/categories/categories.routes')},
            {path: 'departments', title : 'TopSalon - Reparti', loadChildren: () => import('app/modules/admin/departments/departments.routes')},
            {path: 'suppliers', title : 'TopSalon - Fornitori', loadChildren: () => import('app/modules/admin/suppliers/suppliers.routes')},
            {path: 'referrals', title : 'TopSalon - Fonti', loadChildren: () => import('app/modules/admin/referrals/referrals.routes')},

            //promo
            {path: 'discountcards', title : 'TopSalon - Tessere sconto', loadChildren: () => import('app/modules/admin/discountcards/discountcards.routes')},
            {path: 'giftcards', title : 'TopSalon - Gift Card', loadChildren: () => import('app/modules/admin/giftcards/giftcards.routes')},
            {path: 'thresholds-points', title : 'TopSalon - Soglie punti', loadChildren: () => import('app/modules/admin/thresholdsPoints/thresholdsPoints.routes')},
            {path: 'service-packages', title : 'TopSalon - Abbonamenti', loadChildren: () => import('app/modules/admin/servicePackages/servicePackages.routes')},
            {path: 'service-plans', title : 'TopSalon - Piani di accumulo', loadChildren: () => import('app/modules/admin/servicePlans/servicePlans.routes')},

            {path: 'discountcards-customer', title : 'TopSalon - Tessere sconto clienti', loadChildren: () => import('app/modules/admin/discountcardsCustomer/discountcardsCustomer.routes')},
            {path: 'giftcards-customer', title : 'TopSalon - Gift Card clienti', loadChildren: () => import('app/modules/admin/giftcardsCustomer/giftcardsCustomer.routes')},
            {path: 'thresholds-points-customer', title : 'TopSalon - Punti clienti', loadChildren: () => import('app/modules/admin/thresholdsPointsCustomer/thresholdsPointsCustomer.routes')},
            {path: 'service-packages-customer', title : 'TopSalon - Abbonamenti clienti', loadChildren: () => import('app/modules/admin/servicePackagesCustomer/servicePackagesCustomer.routes')},
            {path: 'service-plans-customer', title : 'TopSalon - Piani di accumulo clienti', loadChildren: () => import('app/modules/admin/servicePlansCustomer/servicePlansCustomer.routes')},

            //document
            {path: 'documents', title : 'TopSalon - Documenti emessi', loadChildren: () => import('app/modules/admin/documents/documents.routes')},
            {path: 'deleted-documents', title : 'TopSalon - Documenti cancellati', loadChildren: () => import('app/modules/admin/documentsDeleted/documentsDeleted.routes')},

            //report
            {path: 'daily-report', title : 'TopSalon - Giornale di cassa', loadChildren: () => import('app/modules/report/reportDaily/reportDaily.routes')},
            {path: 'monthly-report', title : 'TopSalon - Mensile di cassa', loadChildren: () => import('app/modules/report/reportMontly/reportMontly.routes')},
            {path: 'warehouse', title : 'TopSalon - Inventario magazzino', loadChildren: () => import('app/modules/report/reportWarehouse/reportWarehouse.routes')},
            {path: 'warehouse-detail', title : 'TopSalon - ', loadChildren: () => import('app/modules/report/reportWarehouseDetail/reportWarehouseDetail.routes')},
            {path: 'product-report', title : 'TopSalon - Incasso prodotti', loadChildren: () => import('app/modules/report/reportProduct/reportProduct.routes')},
            {path: 'service-report', title : 'TopSalon - Incasso servizi', loadChildren: () => import('app/modules/report/reportService/reportService.routes')},
            {path: 'employee-report', title : 'TopSalon - Report collaboratori', loadChildren: () => import('app/modules/report/reportEmployee/reportEmployee.routes')},
            {path: 'fiche-report', title : 'TopSalon - Report Fiche', loadChildren: () => import('app/modules/report/reportFiche/reportFiche.routes')},

            //settings
            {path: 'settings', title : 'TopSalon - Impostazioni', loadChildren: () => import('app/modules/admin/settings/settings.routes')},
        ]
    }
];
