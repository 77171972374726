/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'cash',
        title: 'Cassa',
        type: 'aside',
        icon: 'heroicons_outline:banknotes',
        link: '/cash'
    }, {
        id: 'scheduler',
        title: 'Agenda',
        type: 'aside',
        icon: 'heroicons_outline:calendar',
        link: '/scheduler'
    }, {
        id: 'workshift',
        title: 'Turni',
        type: 'aside',
        icon: 'heroicons_outline:clock',
        link: '/workshift'
    }, {
        id: 'table',
        title: 'Anagrafiche',
        type: 'group',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'customers',
                title: 'Clienti',
                type: 'basic',
                link: '/customers',
            }, {
                id: 'employees',
                title: 'Collaboratori',
                type: 'basic',
                link: '/employees',
            }, {
                id: 'pricelists',
                title: 'Listini',
                type: 'basic',
                link: '/pricelists',
            }, {
                id: 'referrals',
                title: 'Fonti',
                type: 'basic',
                link: '/referrals',
            }, {
                id: 'services',
                title: 'Servizi',
                type: 'basic',
                link: '/services',
            }, {
                id: 'products',
                title: 'Prodotti',
                type: 'basic',
                link: '/products',
            }
            , {
                id: 'suppliers',
                title: 'Fornitori',
                type: 'basic',
                link: '/suppliers',
            },
            {
                id: 'categories',
                title: 'Categorie',
                type: 'basic',
                link: '/categories',
            },{
                id: 'departments',
                title: 'Reparti',
                type: 'basic',
                link: '/departments',
            }

        ]
    }, {
        id: 'promo',
        title: 'Promozioni',
        type: 'group',
        icon: 'heroicons_outline:gift',
        children: [
            {
                id: 'promo',
                title: 'MANAGER',
                subtitle: 'manager',
                type: 'group',
                icon: 'heroicons_outline:gift',
            },
            {
                id: 'discountcards',
                title: 'Tessere sconto',
                type: 'basic',
                link: '/discountcards',
            },
            {
                id: 'giftcards',
                title: 'Gift Card',
                type: 'basic',
                link: '/giftcards',
            }
            ,
            {
                id: 'thresholdsPoints',
                title: 'ThresholdsPoints',
                type: 'basic',
                link: '/thresholds-points',
            }
            ,
            {
                id: 'servicePackages',
                title: 'ServicePackages',
                type: 'basic',
                link: '/service-packages',
            }
            ,
            {
                id: 'servicePlans',
                title: 'ServicePlans',
                type: 'basic',
                link: '/service-plans',
            },
            {
                id: 'dividerCustomer',
                title: 'Clienti',
                type: 'divider',
            }
            ,
            {
                id: 'promo',
                title: 'CUSTOMER',
                subtitle: 'customer',
                type: 'group',
                icon: 'heroicons_outline:gift',
            },
            {
                id: 'discountcardsCustomer',
                title: 'DiscountcardsCustomer',
                type: 'basic',
                link: '/discountcards-customer',
            },
            {
                id: 'giftcardsCustomer',
                title: 'GiftCardCustomer',
                type: 'basic',
                link: '/giftcards-customer',
            }
            ,
            {
                id: 'thresholdsPointsCustomer',
                title: 'ThresholdsPointsCustomer',
                type: 'basic',
                link: '/thresholds-points-customer',
            }
            ,
            {
                id: 'servicePackagesCustomer',
                title: 'ServicePackagesCustomer',
                type: 'basic',
                link: '/service-packages-customer',
            }
            ,
            {
                id: 'servicePlansCustomer',
                title: 'ServicePlansCustomer',
                type: 'basic',
                link: '/service-plans-customer',
            }
        ]
    }, {
        id: 'document',
        title: 'Documenti',
        type: 'group',
        icon: 'heroicons_outline:document-text',
        children: [

            {
                id: 'documents',
                title: 'Documenti emessi',
                type: 'basic',
                link: '/documents',
            },
            {
                id: 'documentsDeleted',
                title: 'Documenti cancellati',
                type: 'basic',
                link: '/deleted-documents',
            }

        ]
    }, {
        id: 'report',
        title: 'Report',
        type: 'group',
        icon: 'heroicons_outline:chart-bar',
        children: [
            {
                id: 'reportDaily',
                title: 'Report finanziario',
                type: 'basic',
                link: '/daily-report',
            }, {
                id: 'reportMonthly',
                title: 'Mensile cassa',
                type: 'basic', 
                link: '/monthly-report',
            }, {
                id: 'reportWarehouse',
                title: 'Inventario magazzino',
                type: 'basic',
                link: '/warehouse',
            }, {
                id: 'reportProduct',
                title: 'Volumi prodotti',
                type: 'basic',
                link: '/product-report',
            }, {
                id: 'reportService',
                title: 'Volumi servizi',
                type: 'basic',
                link: '/service-report',
            }, {
                id: 'reportEmployee',
                title: 'Collaboratori',
                type: 'basic',
                link: '/employee-report',
            }, {
                id: 'reportFiche',
                title: 'Fiche',
                type: 'basic',
                link: '/fiche-report',
            }

        ]
    }


];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'cash',
        title: 'Cassa',
        type: 'basic',
        icon: 'heroicons_outline:banknotes',
        link: '/cash',
        meta : 'Admin,Responsabile,Receptionist,Collaboratore'
    }, {
        id: 'scheduler',
        title: 'Agenda',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/scheduler',
        meta : 'Admin,Responsabile,Receptionist'
    }, {
        id: 'workshift',
        title: 'Turni',
        type: 'basic',
        icon: 'heroicons_outline:clock',
        link: '/workshift',
        meta : 'Admin,Responsabile'
    }, {
        id: 'table',
        title: 'Anagrafiche',
        type: 'aside',
        tooltip: 'Anagrafiche',
        icon: 'heroicons_outline:users',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation,
        meta : 'Admin,Responsabile'
    }, {
        id: 'promo',
        title: 'Promozioni',
        type: 'aside',
        icon: 'heroicons_outline:gift',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation,
        meta : 'Admin,Responsabile'
    }, {
        id: 'document',
        title: 'Documenti',
        type: 'aside',
        icon: 'heroicons_outline:document-text',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation,
        meta : 'Admin,Responsabile'
    }, {
        id: 'report',
        title: 'Reports',
        type: 'aside',
        icon: 'heroicons_outline:chart-bar',
        link: '/report',
        meta : 'Admin,Responsabile,Marketing'
    }

];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];