import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { GridModule, PageService, ResizeService,EditService, CommandColumnService, SortService, FilterService } from '@syncfusion/ej2-angular-grids';
import { CustomerDTO } from 'app/domain/model';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'customer-birthday-popup',
  standalone: true,
  templateUrl: './customer-birthday-popup.component.html',
  styleUrls: ['./customer-birthday-popup.component.scss'],
  imports: [TranslocoModule, MatIconModule,  GridModule, CommonModule],
  providers: [PageService, ResizeService,EditService, CommandColumnService, SortService, FilterService]
})
export class CustomerBirthdayPopupComponent implements OnInit {
  
  public customers : Array<CustomerDTO> = [];
 
  
  constructor(private dialogRef: MatDialogRef<CustomerBirthdayPopupComponent>) {
  }
  
  ngOnInit(): void {
 
 
  }

  getAge(customer : CustomerDTO)
  {
    let timeDiff = Math.abs(Date.now() - (new Date(customer.birtday)).getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);

    return age;
  }

   
   
  closeDialog()
  {
    this.dialogRef.close();
  }
  
}
