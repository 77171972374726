import { NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs';

import { L10n, setCulture } from '@syncfusion/ej2-base';
import { loadCldr, setCurrencyCode } from '@syncfusion/ej2-base';
import * as numberingSystems from '../../../../../node_modules/cldr-data/supplemental/numberingSystems.json';
import * as gregorian from '../../../../../node_modules/cldr-data/main/it/ca-gregorian.json';
import * as numbers from '../../../../../node_modules/cldr-data/main/it/numbers.json';
import * as timeZoneNames from '../../../../../node_modules/cldr-data/main/it/timeZoneNames.json';
import * as it from '../../../../../node_modules/@syncfusion/ej2-locale/src/it.json';
import * as en from '../../../../../node_modules/@syncfusion/ej2-locale/src/en-GB.json';
import * as el from '../../../../../node_modules/@syncfusion/ej2-locale/src/de.json';
import * as es from '../../../../../node_modules/@syncfusion/ej2-locale/src/es.json';
import * as fr from '../../../../../node_modules/@syncfusion/ej2-locale/src/fr.json';
import currencies from '../../../../../node_modules/cldr-data/main/it/currencies.json';

 
@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor],
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {
            // Get the active lang
            this.activeLang = activeLang;

            setCulture(activeLang);
            setCurrencyCode('EUR');
            switch (activeLang) {
                case 'it':
                    L10n.load(it);
                    break;
                case 'en':
                    L10n.load(en);
                    break;
                case 'el':
                    L10n.load(el);
                    break;
                case 'fr':
                    L10n.load(fr);
                    break;
                case 'es':
                    L10n.load(es);
                    break;
                default:
                    L10n.load(it);
                    break;

            }



            // Angular CLI 8.0 and above versions
            loadCldr(numberingSystems['default'], gregorian['default'], numbers['default'], timeZoneNames['default'], currencies);

            // Update the navigation
            this._updateNavigation(activeLang);
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            'it': 'it',
            'en': 'en',
            'el': 'el',
            'fr': 'fr',
            'es': 'es',
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {

        // Set the active lang
        this._translocoService.setActiveLang(lang);
        setCulture(lang);
        setCurrencyCode('EUR');
        switch (lang) {
            case 'it':
                L10n.load(it);
                break;
            case 'en':
                L10n.load(en);
                break;
            case 'el':
                L10n.load(el);
                break;
            case 'fr':
                L10n.load(fr);
                break;
            case 'es':
                L10n.load(es);
                break;
            default:
                L10n.load(it);
                break;

        }
        // Angular CLI 8.0 and above versions
        loadCldr(numberingSystems['default'], gregorian['default'], numbers['default'], timeZoneNames['default'], currencies);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // console.log(lang);
        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        if(navigation)
            navigation.forEach((FuseNavigationItem) => {

                const projectDashboardItem = this._fuseNavigationService.getItem(FuseNavigationItem.id, navigation);
                if (projectDashboardItem) {
                    this._translocoService.selectTranslate(FuseNavigationItem.id).pipe(take(1))
                        .subscribe((translation) => {
                            // Set the title
                            projectDashboardItem.title = translation;

                            // Refresh the navigation component
                            navComponent.refresh();
                        });

                    //console.log(FuseNavigationItem.id);
                }

                if (FuseNavigationItem.children) {
                    FuseNavigationItem.children.forEach((FuseNavigationItem) => {
                        const projectDashboardItem = this._fuseNavigationService.getItem(FuseNavigationItem.id, navigation);
                        if (projectDashboardItem) {
                            this._translocoService.selectTranslate(FuseNavigationItem.id).pipe(take(1))
                                .subscribe((translation) => {
                                    // Set the title
                                    projectDashboardItem.title = translation;

                                    // Refresh the navigation component
                                    navComponent.refresh();
                                });
                            //console.log(FuseNavigationItem.id);
                        }

                    });

                }
            });

        /* // Get the Project dashboard item and update its title
        const projectDashboardItem = this._fuseNavigationService.getItem('cash', navigation);
        if ( projectDashboardItem )
        {
            this._translocoService.selectTranslate('cash').pipe(take(1))
                .subscribe((translation) =>
                {
                    // Set the title
                    projectDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Analytics dashboard item and update its title
        const analyticsDashboardItem = this._fuseNavigationService.getItem('scheduler', navigation);
        if ( analyticsDashboardItem )
        {
            this._translocoService.selectTranslate('scheduler').pipe(take(1))
                .subscribe((translation) =>
                {
                    // Set the title
                    analyticsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        } */
    }
}
