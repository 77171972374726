export const ApiAuth = {
    Login: 'login', 
    Users: 'auth/users', 
    Alive: 'auth/alive', 
  };

  export const Api = { 
    Category: 'api/category',
    Customer: 'api/customer',
    Referral: 'api/referral',
    Product: 'api/product',
    Employee: 'api/employee',
    EmployeePin: 'api/employee/pin',
    Supplier: 'api/supplier',
    Service: 'api/service',
    Discountcard: 'api/card-discount',
    DiscountcardCustomers: 'api/card-discount/customers',
    Giftcard: 'api/gift-card',
    GiftcardCustomers: 'api/gift-card/customer',
    GiftcardCustomersUpdate: 'api/gift-card/customers',
    GiftcardTransactions: 'api/gift-card/transactions',
    ServicePackage: 'api/service-package',
    ServicePackageStatus: 'api/service-package/status',
    ServicePackageDetails: 'api/service-package/details',
    ServicePackageClose: 'api/service-package/close',
    ServicePackageCustomer: 'api/service-package/customer',
    ServicePackageCustomersUpdate: 'api/service-package/customers',
    ThresholdsPoint: 'api/thresholds',
    PointCustomers: 'api/thresholds/customer',
    UpdateCustomerPoints: 'api/thresholds/point',
    UsePoint: 'api/thresholds/customers',
    ResetPoint: 'api/thresholds/reset',
    ResetCustomerPoint: 'api/thresholds/reset-customer',
    ServicePlan: 'api/service-plan',
    ServicePlanCustomer: 'api/service-plan/customer',
    ServicePlanCustomerDetails: 'api/service-plan/details',
    ServicePlanCustomersUpdate: 'api/service-plan/customers',
    Document: 'api/document',
    DocumentDeleted: 'api/document/deleted',
    DocumentUnpaid: 'api/document/unpaid',
    DocumentByCustomer: 'api/document/customer',
    DocumentLastByCustomer: 'api/document/last',
    DocumentGetPayments: 'api/document/payments',
    DocumentSetEmployee: 'api/document/set-employee',
    DocumentUpdatePayment: 'api/document/set-payment',
    SchedulerShift: 'api/scheduler/shift',
    ShiftByDate: 'api/scheduler/date',
    Scheduler: 'api/scheduler',
    SchedulerByCustomer: 'api/scheduler/customer',
    SchedulerByCustomerToday: 'api/scheduler/customer-today',
    SchedulerByItem: 'api/scheduler/item',
    SchedulerAdd: 'api/scheduler/add',
    SchedulerDrag: 'api/scheduler/drag',
    SchedulerUpdateDate: 'api/scheduler/update-date',
    SchedulerEventTitleUpdate: 'api/scheduler/update',
    WorkShift: 'api/workshift',
    WorkShiftHistory: 'api/workshift/history',
    WorkShiftByDate: 'api/workshift/date',
    Absence: 'api/absence',
    AbsenceByDate: 'api/absence/date',
    Promo: 'api/promo',
    PromoByCustomer: 'api/promo',
    CardDiscountCustomerCreate: 'api/promo/card-discount',
    ServicePlanCustomerCreate: 'api/promo/service-plan',
    CommonServices: 'api/service/commons',
    Pricelist: 'api/pricelist',
    Department: 'api/department',
    Note: 'api/note',
    NoteAlert: 'api/note/alert',
    Setting: 'api/setting',
    SettingComunication: 'api/setting/comunication',

    Carts: 'api/cart/load',
    ConfirmCart: 'api/cart/confirm',
    DeleteCart: 'api/cart/delete',
    UpdateCart: 'api/cart/update',
    UpdateStatusCart: 'api/cart/update-status',
    CreateCartFromScheduler: 'api/cart/init',
  };


  export const Media = { 
    ReportDaily: 'media/report/daily',
    FiscalClousureDay: 'media/report/fiscal-closure',
    ReportMonthly: 'media/report/monthly',
    ReportFiche: 'media/report/fiche',
    ReportCustomer: 'media/report/customer',
    ReportEmployee: 'media/report/employee',
    ReportEmployee2: 'media/report/employee2',
    ReportServices: 'media/report/service',
    ReportProdcuts: 'media/report/product',
    Warehouse: 'media/warehouse',
    WarehouseDetail: 'media/warehouse/detail',
    WarehouseByItem: 'media/warehouse/item',
    WarehouseSync: 'media/warehouse/sync',
    WarehouseLoad: 'media/warehouse/load',
    ImageUpload: 'media/image',
    ImageThumbUrl: 'media/image/thumb',
    ImageByTenant: 'media/image/tenant',
    GetMobytAlias: 'media/setting/sms-alias',
    GetMobytCredit: 'media/setting/sms-credit',
    CustomerFiche: 'media/customer/fiche',
    PayPalGetAction: 'media/payment/get-action',
  };
  